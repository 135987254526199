<ion-header>
  <ion-toolbar style="overflow: hidden">
    <ion-title>{{ title }}</ion-title>
    <img *ngIf="this.showExternalImageOverlay" slot="end" style="max-height: 24px" src="assets/images/www.png" />
    <ion-button slot="end" fill="clear" (click)="closeModal()">
      <ion-icon name="close" class="sis-close-button"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <!-- small Thumbnail Slider -->
  <div
    class="sis-slides-thumbnail-container"
    *ngIf="!this.isOneCam && (this.bigScreenMode$ | async)"
    (mouseenter)="showButtons('top')"
    (mouseleave)="hideButtons('top')"
  >
    <ion-fab vertical="center" horizontal="start" slot="fixed" [class.visible]="showTopButtons" class="top-fab-left">
      <ion-fab-button
        translucent="true"
        color="light"
        size="small"
        (click)="prevThumbnail()"
        [disabled]="this.isPrevThumbnailButtonDisabled"
        test-data="sis-webcam-prev-btn"
      >
        <ion-icon name="chevron-back-outline" style="font-size: 13pt; padding-right: 3px"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-fab
      vertical="center"
      horizontal="end"
      slot="fixed"
      [class.visible]="this.showTopButtons"
      class="top-fab-right"
    >
      <ion-fab-button
        translucent="true"
        color="light"
        size="small"
        (click)="nextThumbnail()"
        [disabled]="this.isNextThumbnailButtonDisabled"
        test-data="sis-webcam-next-btn"
      >
        <ion-icon name="chevron-forward-outline" style="font-size: 13pt; padding-left: 3px"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <swiper-container
      #swipertop
      [pager]="true"
      [loop]="false"
      class="sis-swiper-thumbnail-container"
      space-between="10"
      slides-per-view="5"
      centered-slides="false"
      free-mode="true"
    >
      <swiper-slide
        *ngFor="let webcamDisplayConfig of webcamDisplayConfigs; let i = index"
        (click)="onThumbnailClick(i)"
        class="swiper-slide-top"
      >
        <div class="sis-thumbnail-container">
          <img
            (error)="onImageError($event, i)"
            (load)="onImageLoad(webcamDisplayConfig)"
            *ngIf="
              !webcamDisplayConfig.showNotFoundOverlay && webcamDisplayConfig.urlWithRandomString$ | async as imageSrc
            "
            [src]="imageSrc"
            test-data="sis-webcam-thumbnail-img"
            [class.visible]="!webcamDisplayConfig.showNotFoundOverlay"
            class="swiper-slide-thumbnail-img enlarged-image"
            [ngClass]="
              webcamDisplayConfig.showLoadingOverlay ||
              webcamDisplayConfig.showNotFoundOverlay ||
              webcamDisplayConfig.showOutdatedOverlay
                ? 'sis-overlay-thumbnail'
                : ''
            "
          />
        </div>
        <div class="sis-thumbnail-title">
          <span class="thumbnail-title">{{ webcamDisplayConfig.webcam.title }}</span>
        </div>
        <div
          class="sis-overlay-text"
          *ngIf="webcamDisplayConfig.showOutdatedOverlay && !webcamDisplayConfig.showNotFoundOverlay"
        >
          {{ 'webcam.image.outdated' | translate }}
        </div>
        <div
          class="sis-overlay-text"
          *ngIf="
            webcamDisplayConfig.showLoadingOverlay &&
            this.canShowLoadingOverlay &&
            !webcamDisplayConfig.showOutdatedOverlay &&
            !webcamDisplayConfig.showNotFoundOverlay
          "
        >
          {{ 'webcam.image.loading' | translate }}
        </div>
        <div class="sis-overlay-text" *ngIf="webcamDisplayConfig.showNotFoundOverlay">
          {{ 'webcam.image.notfound' | translate }}
        </div>
      </swiper-slide>
    </swiper-container>
  </div>

  <!-- Large Image Slider with Panzoom -->
  <div class="sis-image-container" (mouseenter)="showButtons('bottom')" (mouseleave)="hideButtons('bottom')">
    <ion-fab
      vertical="center"
      horizontal="start"
      slot="fixed"
      class="bottom-fab-left"
      *ngIf="!isOneCam"
      [class.visible]="this.showBottomButtons"
    >
      <ion-fab-button
        translucent="true"
        color="light"
        size="small"
        (click)="prevImage()"
        [disabled]="this.isPrevImageButtonDisabled"
        test-data="sis-webcam-prev-image-btn"
      >
        <ion-icon name="chevron-back-outline" style="font-size: 13pt; padding-right: 3px"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-fab
      vertical="center"
      horizontal="end"
      slot="fixed"
      class="bottom-fab-right"
      *ngIf="!isOneCam"
      [class.visible]="this.showBottomButtons"
    >
      <ion-fab-button
        translucent="true"
        color="light"
        size="small"
        (click)="nextImage()"
        [disabled]="this.isNextImageButtonDisabled"
        test-data="sis-webcam-next-image-btn"
      >
        <ion-icon name="chevron-forward-outline" style="font-size: 13pt; padding-left: 3px"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <swiper-container
      #swiperbottom
      [pager]="false"
      class="sis-swiper-container-image"
      space-between="0"
      slides-per-view="1"
      centered-slides="true"
      free-mode="true"
      [style.overflow]="(this.bigScreenMode$ | async) ? 'hidden' : 'visible'"
    >
      <swiper-slide
        *ngFor="let webcamDisplayConfig of this.webcamDisplayConfigs; let i = index"
        class="swiper-slide-image"
      >
        <div class="swiper-zoom-container">
          <img
            (error)="onImageError($event, i)"
            (load)="onImageLoad(webcamDisplayConfig)"
            *ngIf="webcamDisplayConfig.urlWithRandomString$ | async as imageSrc"
            [src]="imageSrc"
            class="swiper-slide-image-img panzoom"
            [ngClass]="
              webcamDisplayConfig.showLoadingOverlay ||
              webcamDisplayConfig.showNotFoundOverlay ||
              webcamDisplayConfig.showOutdatedOverlay
                ? 'sis-overlay-image'
                : ''
            "
          />
          <div class="sis-overlay-text" *ngIf="webcamDisplayConfig.showNotFoundOverlay">
            {{ 'webcam.image.notfound' | translate }}
          </div>
          <div
            class="sis-overlay-text"
            *ngIf="
              webcamDisplayConfig.showLoadingOverlay &&
              this.canShowLoadingOverlay &&
              !webcamDisplayConfig.showOutdatedOverlay &&
              !webcamDisplayConfig.showNotFoundOverlay
            "
          >
            {{ 'webcam.image.loading' | translate }}
          </div>
          <div
            *ngIf="webcamDisplayConfig.showOutdatedOverlay && !webcamDisplayConfig.showNotFoundOverlay"
            class="sis-overlay-text"
          >
            {{ 'webcam.image.outdated' | translate }}
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</ion-content>
