import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, timer } from 'rxjs';
import { distinctUntilChanged, map, share, switchMap, tap } from 'rxjs/operators';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebcamImageRequestService {
  private static readonly requestOutdatedTime = 1.5 * 60 * 1000;
  private static readonly requiredFeature = new Feature(FeatureId.COCKPIT_WEBCAM, FeatureAccessLevel.READ);
  private static readonly requestUploadRenewalIntervalMs = 10000;

  private lastImageUploadRequest: Date;
  private lastPeriodicRequestStart: Date;

  private readonly imageUploadRequestedRopewaySisId$ = new ReplaySubject<string>(1);
  private readonly url = `${environment.baseUrlApi}/imageupload/requestupload`;

  readonly imageUploadRequests$: Observable<void> = this.destinationService.selectedTenantFeatures$.pipe(
    switchMap((features) => {
      this.lastImageUploadRequest = undefined;
      if (features.some((f) => f.hasMinimumRequirementFor(WebcamImageRequestService.requiredFeature))) {
        return this.imageUploadRequestedRopewaySisId$.pipe(
          distinctUntilChanged(),
          tap(() => (this.lastPeriodicRequestStart = new Date())),
          switchMap((deviceSisId) =>
            timer(0, WebcamImageRequestService.requestUploadRenewalIntervalMs).pipe(
              switchMap(() => this.httpClient.get(`${this.url}/${deviceSisId}`))
            )
          ),
          map((response) => {
            if (response === 200) {
              this.lastImageUploadRequest = new Date();
            }
            return null;
          })
        );
      } else {
        return EMPTY;
      }
    }),
    share()
  );

  constructor(private httpClient: HttpClient, private destinationService: DestinationService) {}

  periodicallyRequestImageUploads(ropewaySisId: string | 'none'): Observable<void> {
    this.imageUploadRequestedRopewaySisId$.next(ropewaySisId);
    return this.imageUploadRequests$;
  }

  isImageRequestOutdated(): boolean {
    return (
      !this.lastImageUploadRequest ||
      Date.now() - this.lastImageUploadRequest.getTime() > WebcamImageRequestService.requestOutdatedTime
    );
  }

  didRecentlyStartUploadRequests(): boolean {
    return this.lastPeriodicRequestStart && Date.now() - this.lastPeriodicRequestStart.getTime() < 20000;
  }
}
