import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { filter, map, startWith, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { WebcamImageUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Webcam } from 'src/app/maps/domain/webcam.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WebcamService } from 'src/app/maps/widget-sidepane/webcam/webcam.service';
import { WebcamConfig } from 'src/app/maps/widget-sidepane/webcam/webcam-config.model';
import { WebcamImageRequestService } from 'src/app/maps/widget-sidepane/webcam/webcam-image-request.service';
import { WebcamModalComponent } from 'src/app/maps/widget-sidepane/webcam/webcam-modal/webcam-modal.component';
import { Swiper } from 'swiper';

@Component({
  selector: 'sis-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
})
export class WebcamComponent extends Unsubscriber implements AfterViewInit, OnInit, OnChanges {
  private static readonly imageOutdatedTime = 2 * 60 * 1000;
  private static readonly publicImageRefreshTimeMs = 5000;

  @Input() startIndex = 0;
  @Input() isModal: boolean;

  @Output() imageIndex = new EventEmitter();

  @ViewChild('swiper') swiperRef: ElementRef;

  end: boolean;
  start = true;
  imageCssClasses: string;
  showOutdatedOverlay: boolean;
  showNotFoundOverlay: boolean;
  showLoadingOverlay: boolean;
  canShowLoadingOverlay: boolean;
  webcamDisplayConfigs: WebcamConfig[] = [];

  sliderOptions = {
    zoom: {
      maxRatio: 5,
    },
  };

  private webcams: Webcam[] = [];
  private activeIndex = 0;
  private stopTimer$ = new Subject<void>();
  private activeIndex$ = new BehaviorSubject<number>(0);
  private swiper: Swiper;

  private readonly webcamImageUpdated$ = this.eventBusService.observe(WebcamImageUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([event, tenant]) => event.tenantGuid === tenant.guid),
    map(([event]) => event)
  );

  constructor(
    private modalCtrl: ModalController,
    private webcamImageRequestService: WebcamImageRequestService,
    private webcamService: WebcamService,
    private changeDetector: ChangeDetectorRef,
    private selectedMapElementService: SelectedMapElementService,
    private eventBusService: EventBusService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.onDestroy$.pipe(take(1)).subscribe(() => {
      this.stopTimer$.next();
      this.stopTimer$.complete();
    });

    this.selectedMapElementService.selectedMapElement$.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (event.codeTriggered) {
        return;
      }

      const webcams = event.ropeway?.webcams?.length
        ? event.ropeway.webcams
        : event.meteoStation?.webcams?.length
          ? event.meteoStation.webcams
          : event.customMarker?.webcams;

      if (this.webcams === webcams) {
        return;
      }

      const ropewaySisId = event.ropeway?.sisId ?? 'none';
      this.webcamImageRequestService.periodicallyRequestImageUploads(ropewaySisId);

      if (!webcams?.length) {
        this.webcams = [];
        this.webcamDisplayConfigs = [];
        this.stopTimer$.next();
      } else {
        this.setOutdatedOverlay(this.showOutdatedOverlay);
        this.setLoadingOverlay(true);
        this.setNotFoundOverlay(false);
        this.canShowLoadingOverlay = false;

        this.webcams = webcams;
        this.webcamDisplayConfigs = webcams.map((webcam, index) => {
          const url$ = this.activeIndex$.pipe(
            switchMap((activeIndex) => {
              if (index !== activeIndex) {
                return of(null);
              }
              if (webcam.isPublic) {
                return of(webcam.url);
              }
              return this.webcamService.sasToken$.pipe(
                map((sasToken) => (sasToken != null ? `${webcam.url}${sasToken}` : null))
              );
            })
          );

          return {
            webcam,
            url$,
            urlWithRandomString$: this.getUrlWithRandomString$(url$, webcam.isPublic),
          };
        });

        this.setActiveIndex(0);

        if (this.swiper) {
          this.swiper.slideTo(this.startIndex, 0);
        }
      }
    });

    this.webcamImageRequestService.imageUploadRequests$.pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  ngAfterViewInit() {
    this.swiper = this.swiperRef.nativeElement.swiper;

    this.swiper.on('slideChange', () => this.updateButtons());
    this.swiper.on('click', () => this.showModal());

    if (this.startIndex >= this.webcams.length || this.startIndex < 0) {
      this.startIndex = 0;
      this.start = true;
    } else {
      if (this.startIndex > 0) {
        this.swiper.slideTo(this.startIndex, 0);
      }
    }
  }

  async ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.webcams?.currentValue || simpleChanges.slides?.currentValue) {
      if (this.webcams && this.swiper) {
        this.swiper.update();
        this.swiper.slideTo(0, 0);
      }
    }
  }

  prevSlide(): void {
    this.resetValuesForNewImage();
    this.setActiveIndex(this.activeIndex === 0 ? 0 : --this.activeIndex);
    this.swiper.slidePrev();
  }

  nextSlide(): void {
    this.resetValuesForNewImage();
    this.setActiveIndex(this.activeIndex === this.webcams.length - 1 ? this.activeIndex : ++this.activeIndex);
    this.swiper.slideNext();
  }

  onImageLoad(webcamDisplayConfig: WebcamConfig): void {
    if (webcamDisplayConfig.webcam.isPublic) {
      this.setLoadingOverlay(false);
      this.setOutdatedOverlay(false);
      return;
    }
    webcamDisplayConfig.url$
      .pipe(
        switchMap((url) =>
          ajax({
            url,
            method: 'HEAD',
            responseType: 'json',
          })
        ),
        take(1)
      )
      .subscribe({
        next: (res) => {
          const lastModified = res.xhr.getResponseHeader('last-modified');
          const parsedDate = Date.parse(lastModified);

          const now = Date.now();
          const outdated = now - parsedDate > WebcamComponent.imageOutdatedTime;
          const imageRequestOutdated = this.webcamImageRequestService.isImageRequestOutdated();

          // display outdated only if the image is outdated and the upload request is not outdated
          this.setOutdatedOverlay(outdated && !imageRequestOutdated);

          // display loading if the image is outdated and either the upload request is outdated or was started recently
          this.setLoadingOverlay(
            outdated && (imageRequestOutdated || this.webcamImageRequestService.didRecentlyStartUploadRequests())
          );
        },
        error: () => {
          this.setLoadingOverlay(false);
        },
      });
  }

  onImageError(event: any): void {
    event.target.style.visibility = 'hidden';
    this.setNotFoundOverlay(true);
  }

  async showModal(): Promise<void> {
    if (!this.isModal) {
      const startIndex = this.swiper.activeIndex;
      const cssClass = this.swiper.slides.length <= 1 ? 'sis-one-cam-modal' : 'sis-webcam-modal';
      const modal = await this.modalCtrl.create({
        component: WebcamModalComponent,
        componentProps: {
          startIndex,
          webcams: this.webcams,
          isModal: true,
        },
        cssClass: cssClass,
      });

      return modal.present();
    }
  }

  private updateButtons() {
    const index = this.swiper.activeIndex;
    this.setActiveIndex(index);
    this.imageIndex.emit(index);
    this.start = index === 0;
    this.end = index === this.webcams.length - 1;
    this.changeDetector.detectChanges();
  }

  private setOutdatedOverlay(visible: boolean): void {
    this.showOutdatedOverlay = visible;
    this.setImageCssClass();
  }

  private setLoadingOverlay(visible: boolean): void {
    this.showLoadingOverlay = visible;
    this.canShowLoadingOverlay = false;
    timer(750)
      .pipe(takeUntil(this.stopTimer$))
      .subscribe(() => (this.canShowLoadingOverlay = true));
    this.setImageCssClass();
  }

  private setNotFoundOverlay(visible: boolean): void {
    this.showNotFoundOverlay = visible;
    this.setImageCssClass();
  }

  private setImageCssClass(): void {
    let cssClasses = 'sis-image';

    cssClasses = cssClasses.concat(this.isModal ? ' sis-image-modal' : ' sis-image-sidepane');

    if (this.showOutdatedOverlay || this.showLoadingOverlay || this.showNotFoundOverlay) {
      cssClasses = cssClasses.concat(' sis-image-overlay');
    }

    this.imageCssClasses = cssClasses;
  }

  private resetValuesForNewImage(): void {
    this.stopTimer$.next();
    this.setOutdatedOverlay(false);
    this.setLoadingOverlay(true);
    this.setNotFoundOverlay(false);
  }

  private setActiveIndex(index: number): void {
    this.activeIndex = index;
    this.activeIndex$.next(index);
  }

  private getUrlWithRandomString$(url$: Observable<string>, isPublic: boolean): Observable<string> {
    return url$.pipe(
      switchMap((url) => {
        if (!url) {
          return of(null);
        }

        if (isPublic) {
          return timer(0, WebcamComponent.publicImageRefreshTimeMs).pipe(
            map(() => `${url}?${(Math.random() * 10000).toString()}`)
          );
        }

        return this.webcamImageUpdated$.pipe(
          filter((event) => url.startsWith(event.url)),
          startWith(url),
          map(() => `${url}&${(Math.random() * 10000).toString()}`)
        );
      })
    );
  }
}
