<div class="sis-slides-container">
  <ion-fab
    *ngIf="!webcamDisplayConfigs || webcamDisplayConfigs.length > 1"
    vertical="center"
    horizontal="start"
    slot="fixed"
  >
    <ion-fab-button
      translucent="true"
      color="light"
      size="small"
      [disabled]="start"
      (click)="prevSlide()"
      test-data="sis-webcam-prev-btn"
    >
      <ion-icon name="chevron-back-outline" style="font-size: 13pt; padding-right: 3px"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-fab
    *ngIf="!webcamDisplayConfigs || webcamDisplayConfigs.length > 1"
    vertical="center"
    horizontal="end"
    slot="fixed"
  >
    <ion-fab-button
      translucent="true"
      color="light"
      size="small"
      [disabled]="end"
      (click)="nextSlide()"
      test-data="sis-webcam-next-btn"
    >
      <ion-icon
        name="chevron-forward-outline"
        style="font-size: 13pt; padding-left: 3px; pointer-events: none"
      ></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <swiper-container
    #swiper
    [pager]="true"
    class="sis-swiper-container"
    [options]="this.isModal ? sliderOptions : null"
    [attr.zoom]="this.isModal ? 'zoom' : null"
  >
    <swiper-slide
      *ngFor="let webcamDisplayConfig of webcamDisplayConfigs"
      [ngClass]="{ 'sis-swiper-slide': this.isModal }"
    >
      <div
        class="sis-slide-container"
        [ngClass]="this.isModal ? 'sis-slide-container-modal' : 'sis-slide-container-small'"
      >
        <div *ngIf="!this.isModal" class="sis-image-title">
          <ion-label>{{ webcamDisplayConfig.webcam.title }} </ion-label>
          <img
            *ngIf="webcamDisplayConfig.webcam.isPublic"
            class="sis-label-globe-overlay"
            src="assets/images/www.png"
          />
        </div>
        <div class="sis-webcam-image-container">
          <div [ngClass]="this.isModal ? 'swiper-zoom-container' : null">
            <img
              *ngIf="webcamDisplayConfig.urlWithRandomString$ | async as imageSrc"
              [ngClass]="this.imageCssClasses"
              [src]="imageSrc"
              (load)="this.onImageLoad(webcamDisplayConfig)"
              (error)="this.onImageError($event)"
              test-data="sis-webcam-img"
            />
          </div>
          <div class="sis-overlay-text" *ngIf="this.showOutdatedOverlay && !this.showNotFoundOverlay">
            {{ 'webcam.image.outdated' | translate }}
          </div>
          <div
            class="sis-overlay-text"
            *ngIf="
              this.showLoadingOverlay &&
              this.canShowLoadingOverlay &&
              !this.showOutdatedOverlay &&
              !this.showNotFoundOverlay
            "
          >
            {{ 'webcam.image.loading' | translate }}
          </div>
          <div class="sis-overlay-text" *ngIf="this.showNotFoundOverlay">
            {{ 'webcam.image.notfound' | translate }}
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</div>
