import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import panzoom from '@panzoom/panzoom';
import { firstValueFrom, Observable, of, Subject, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { filter, map, startWith, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { WebcamImageUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Webcam } from 'src/app/maps/domain/webcam.model';
import { WebcamService } from 'src/app/maps/widget-sidepane/webcam/webcam.service';
import { WebcamConfig } from 'src/app/maps/widget-sidepane/webcam/webcam-config.model';
import { WebcamImageRequestService } from 'src/app/maps/widget-sidepane/webcam/webcam-image-request.service';
import { Swiper } from 'swiper';

@Component({
  selector: 'sis-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss'],
})
export class WebcamModalComponent extends Unsubscriber implements OnInit {
  private static readonly imageOutdatedTime = 2 * 60 * 1000;
  private static readonly publicImageRefreshTimeMs = 5000;

  @Input() updateInterval = 5000;
  @Input() webcams: Webcam[];
  @Input() startIndex = 0;

  @ViewChild('swipertop', { static: false }) topSwiperRef: ElementRef;
  @ViewChild('onecam', { static: false }) oneCamRef: ElementRef;
  @ViewChild('swiperbottom', { static: false }) bottomSwiperRef: ElementRef;

  title: string;
  showExternalImageOverlay: boolean;
  canShowLoadingOverlay: boolean;
  webcamDisplayConfigs: WebcamConfig[] = [];

  isPrevThumbnailButtonDisabled = true;
  isNextThumbnailButtonDisabled = false;
  isPrevImageButtonDisabled = true;
  isNextImageButtonDisabled = false;
  showTopButtons = false;
  showBottomButtons = false;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  private stopTimer$ = new Subject<void>();
  private topSwiper: Swiper | undefined;
  private bottomSwiper: Swiper | undefined;
  private panzoomInstances: Map<number, ReturnType<typeof panzoom>> = new Map();

  get isOneCam(): boolean {
    return this.webcams && this.webcams.length === 1;
  }

  private readonly webcamImageUpdated$ = this.eventBusService.observe(WebcamImageUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([event, tenant]) => event.tenantGuid === tenant.guid),
    map(([event]) => event)
  );

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private webcamImageRequestService: WebcamImageRequestService,
    private webcamService: WebcamService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private eventBusService: EventBusService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.initWebcamConfigs();
    this.setImageIndex(this.startIndex);
    if (!(await firstValueFrom(this.bigScreenMode$))) {
      this.showButtons("bottom");
    }
  }

  ngAfterViewInit() {
    this.initializeSwipers();
  }

  onThumbnailClick(index: number) {
    this.startIndex = index;
    this.setImageIndex(index);

    if (this.bottomSwiper) {
      this.bottomSwiper.slideTo(index);
    }
  }

  showButtons(area: 'top' | 'bottom') {
    if (area === 'top' && !this.topSwiper?.isLocked) {
      this.showTopButtons = true;
    } else if (area === 'bottom') {
      this.showBottomButtons = true;
    }
  }

  hideButtons(area: 'top' | 'bottom') {
    if (area === 'top') {
      this.showTopButtons = false;
    } else if (area === 'bottom') {
      this.showBottomButtons = false;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  setImageIndex(index: number) {
    if (this.webcams && this.webcams.length > index) {
      const webcam = this.webcams[index];
      this.title = webcam.title;
      this.showExternalImageOverlay = webcam.isPublic;
    } else {
      this.title = '';
      this.showExternalImageOverlay = false;
    }
  }

  prevThumbnail(): void {
    if (this.topSwiper && !this.topSwiper.isBeginning) {
      this.topSwiper.slidePrev();
    }
  }

  nextThumbnail(): void {
    if (this.topSwiper && !this.topSwiper.isEnd) {
      this.topSwiper.slideNext();
    }
  }

  prevImage(): void {
    if (this.bottomSwiper && !this.bottomSwiper.isBeginning) {
      this.resetSwiperZoom();
      this.bottomSwiper.slidePrev();
    }
  }

  nextImage(): void {
    if (this.bottomSwiper && !this.bottomSwiper.isEnd) {
      this.resetSwiperZoom();
      this.bottomSwiper.slideNext();
    }
  }

  onImageLoad(webcamDisplayConfig: WebcamConfig): void {
    webcamDisplayConfig.showNotFoundOverlay = false;
    webcamDisplayConfig.showOutdatedOverlay = false;
    if (webcamDisplayConfig.webcam.isPublic) {
      this.setLoadingOverlay(false, webcamDisplayConfig);
      this.setOutdatedOverlay(false, webcamDisplayConfig);
      return;
    }
    webcamDisplayConfig.url$
      .pipe(
        switchMap((url) =>
          ajax({
            url,
            method: 'HEAD',
            responseType: 'json',
          })
        ),
        take(1)
      )
      .subscribe({
        next: (res) => {
          const lastModified = res.xhr.getResponseHeader('last-modified');
          const parsedDate = Date.parse(lastModified);

          const now = Date.now();
          const outdated = now - parsedDate > WebcamModalComponent.imageOutdatedTime;
          const imageRequestOutdated = this.webcamImageRequestService.isImageRequestOutdated();

          this.setOutdatedOverlay(outdated && !imageRequestOutdated, webcamDisplayConfig);
          this.setLoadingOverlay(outdated && (imageRequestOutdated || this.webcamImageRequestService.didRecentlyStartUploadRequests()), webcamDisplayConfig);
        },
        error: () => {
          this.setLoadingOverlay(false, webcamDisplayConfig);
        },
      });
  }

  onImageError(event: any, index: number): void {
    event.target.style.visibility = 'hidden';
    this.webcamDisplayConfigs[index].showLoadingOverlay = false;
    this.canShowLoadingOverlay = false;
    this.webcamDisplayConfigs[index].showNotFoundOverlay = true;
    this.webcamDisplayConfigs[index].showOutdatedOverlay = false;
    this.changeDetector.detectChanges();
  }

  private setLoadingOverlay(visible: boolean, config: WebcamConfig): void {
    config.showLoadingOverlay = visible;
    this.canShowLoadingOverlay = false;
    timer(750)
      .pipe(takeUntil(this.stopTimer$))
      .subscribe(() => (this.canShowLoadingOverlay = true));
  }

  private setOutdatedOverlay(visible: boolean, config: WebcamConfig): void {
    config.showOutdatedOverlay = visible;
  }

  private initializePanzoom(swiper: Swiper | null) {
    this.cleanupPanzoomInstances();
    const slides = Array.from(swiper.slides) as HTMLElement[];
    slides.forEach((slide, index) => {
      const imageElement = slide.querySelector('.panzoom') as HTMLElement;
      const panzoomInstance = this.setupPanzoomInstance(imageElement);
      this.panzoomInstances.set(index, panzoomInstance);
    });
  }

  private setupPanzoomInstance(imageElement: HTMLElement): any {
    if (imageElement) {
      const minScale = 1;
      const maxScale = 10;
      const panzoomInstance = panzoom(imageElement, {
        contain: 'outside',
        maxScale: maxScale,
        minScale: minScale,
        panOnlyWhenZoomed: true,
      });

      let currentScale = minScale;
      let x: number;
      let y: number;

      imageElement.addEventListener('mousemove', (event) => {
        x = event.clientX;
        y = event.clientY;
      })

      imageElement.addEventListener('wheel', (event) => {
        event.preventDefault();

        const zoomingIn = event.deltaY < 0;
        const scaleDelta = zoomingIn ? 0.1 : -0.1;
        const newScale = Math.min(Math.max(currentScale + scaleDelta, minScale), maxScale);

        const rect = imageElement.getBoundingClientRect();
        const clientX = this.bigScreenMode$ ? x : event.clientX - rect.left;
        const clientY = this.bigScreenMode$ ? y : event.clientY - rect.top;
        const point = {
          clientX,
          clientY
        }

        panzoomInstance.zoomToPoint(newScale, point);
        currentScale = newScale;

        if (!zoomingIn && newScale === minScale) {
          panzoomInstance.pan(0, 0);
          panzoomInstance.zoom(minScale);
          currentScale = minScale;
        }
      })
      return panzoomInstance;
    }
  }

  private resetSwiperZoom() {
    const currentIndex = this.bottomSwiper?.activeIndex ?? 0;
    const panzoomInstance = this.panzoomInstances.get(currentIndex);
    if (panzoomInstance) {
      panzoomInstance.reset();
      panzoomInstance.zoom(1);
      panzoomInstance.pan(0, 0);
    }
  }

  private updateButtons() {
    this.isPrevThumbnailButtonDisabled = this.topSwiper?.isBeginning;
    this.isNextThumbnailButtonDisabled = this.topSwiper?.isEnd;

    this.isPrevImageButtonDisabled = this.bottomSwiper?.isBeginning;
    this.isNextImageButtonDisabled = this.bottomSwiper?.isEnd;

    this.changeDetector.detectChanges();
  }

  private initializeSwipers() {
    setTimeout(() => {
      if (this.topSwiperRef?.nativeElement) {
        this.topSwiper = this.topSwiperRef.nativeElement.swiper;
        this.topSwiper.on('slideChange', () => this.updateButtons());
      }

      if (this.bottomSwiperRef?.nativeElement) {
        this.bottomSwiper = this.bottomSwiperRef.nativeElement.swiper;
        this.bottomSwiper.on('slideChange', () => {
          this.resetSwiperZoom();
          this.updateButtons();
        });
        this.initializePanzoom(this.bottomSwiper);
      }

      this.updateButtons();
      this.bottomSwiper.slideTo(this.startIndex, 0);
    }, 100);
  }

  private initWebcamConfigs() {
    this.webcamDisplayConfigs = this.webcams.map((webcam) => {
      const sasToken$ = webcam.isPublic ? of('?') : this.webcamService.sasToken$;
      const url$ = sasToken$.pipe(
        map((sasToken) => `${webcam.url}${sasToken}`)
      );

      return {
        webcam,
        url$,
        urlWithRandomString$: this.getUrlWithRandomString$(url$, webcam.isPublic),
        showImage$: of(true),
        showLoadingOverlay: true,
        showNotFoundOverlay: false,
        showOutdatedOverlay: false
      };
    });
  }

  private getUrlWithRandomString$(url$: Observable<string>, isPublic: boolean): Observable<string> {
    return url$.pipe(
      switchMap((url) => {
        if (!url) {
          return of(null);
        }

        if (isPublic) {
          return timer(0, WebcamModalComponent.publicImageRefreshTimeMs).pipe(
            map(() => `${url}?${(Math.random() * 10000).toString()}`)
          );
        }

        return this.webcamImageUpdated$.pipe(
          filter((event) => url.startsWith(event.url)),
          startWith(url),
          map(() => `${url}&${(Math.random() * 10000).toString()}`)
        );
      })
    );
  }

  private cleanupPanzoomInstances() {
    this.panzoomInstances.forEach((instance) => {
      instance.destroy();
    });
    this.panzoomInstances.clear();
  }
}
