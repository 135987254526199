/* eslint-disable max-classes-per-file */
import 'reflect-metadata';

import { BreakdownInfoActiveText } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.model';
import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';
import { LogbookEntry } from 'src/app/core/components/logbook/domain/logbook-entry.model';
import { STnetStatus } from 'src/app/core/stnet/stnet-status.model';
import { Alarm } from 'src/app/domain/alarm.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';
import { FeatureManagementFeature } from 'src/app/feature-management/domain/feature.model';
import { LedTicker } from 'src/app/led-ticker/domain/led-ticker.model';
import { AlarmLibraryItem } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.model';
import { UserAlarmConfig } from 'src/app/maps/alarmlibrary/domain/useralarmconfig.model';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayEcoModeSettings } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.model';
import { RopewaySettings } from 'src/app/maps/domain/ropeway-settings/ropeway-settings.model';
import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { InfoBanner } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.model';
import { InfoBannerMapping } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner-mapping.model';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { MeteoInfoItem } from 'src/app/meteo-info/domain/meteo-info-item.model';
import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingStatus } from 'src/app/parking/domain/parking-status.enum';
import { EditToken } from 'src/app/sisag-admin/domain/edit-token.model';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaAssetEdit } from 'src/app/sismedia/domain/sismedia-asset-edit.model';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaAssetSubStatus } from 'src/app/sismedia/domain/sismedia-asset-substatus.enum';
import { SisMediaItemOrder } from 'src/app/sismedia/domain/sismedia-item-order.model';
import { SisMediaAssetOverrideEntry } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.model';
import { SisMediaOperatingHoursEntry } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-entry.model';
import { SisMediaSetting } from 'src/app/sismedia-setting/sismedia-setting.model';
import { STnetItem } from 'src/app/stnet/domain/stnet-item.model';
import { TimetableIntervalDay } from 'src/app/timetable/domain/timetable-interval-day.model';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimeTableRealTimeData } from 'src/app/timetable/domain/timetable-real-time-data.model';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';
import { ManagedTenant } from 'src/app/user-management/domain/managed-tenant.model';
import { User } from 'src/app/user-management/domain/user.model';
import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';

export const ClassName = (className: string): ClassDecorator => {
  return (target) => Reflect.defineMetadata(BusEvent.nameSymbol, className, target.prototype);
};

export abstract class BusEvent {
  static nameSymbol = Symbol('name');
}

@ClassName('MapIconClickedEvent')
export class MapIconClickedEvent extends BusEvent {
  ropeway: Ropeway;
  meteoStation: MeteoStation;
  customMarker: CustomMarker;
  codeTriggered: boolean;
}

@ClassName('MapIconHoveredEvent')
export class MapIconHoveredEvent extends BusEvent {
  ropeway: Ropeway;
  customMarker: CustomMarker;
  hover: boolean;
}

@ClassName('ApiConnectionEvent')
export class ApiConnectionEvent extends BusEvent {
  apiConnected: boolean;
}

@ClassName('InternetConnectionEvent')
export class InternetConnectionEvent extends BusEvent {
  internetConnected: boolean;
}

@ClassName('WidgetSelectedEvent')
export class WidgetSelectedEvent extends BusEvent {
  expandable: boolean;
  codeTriggered: boolean;
}

@ClassName('UserAlarmEvent')
export class UserAlarmEvent extends BusEvent {
  alarm: Alarm;
}

@ClassName('UserSettingsUpdatedEvent')
export class UserSettingsUpdatedEvent extends BusEvent {
  userSettings: UserSettings;
  updateSuccessful: boolean;
  updaterId: string;
}

@ClassName('UserAlarmConfigUpdatedEvent')
export class UserAlarmConfigUpdatedEvent extends BusEvent {
  userAlarmConfig: UserAlarmConfig;
}

@ClassName('DeviceListHoverEvent')
export class DeviceListHoverEvent extends BusEvent {
  hover: boolean;
  deviceSisId: string;
}

@ClassName('BreakdownInfoActiveTextUpdatedEvent')
export class BreakdownInfoActiveTextUpdatedEvent extends BusEvent {
  breakdownInfoActiveTexts: BreakdownInfoActiveText[];
  updateSuccessful: boolean;
}

@ClassName('BreakdownInfoLibraryUpdatedEvent')
export class BreakdownInfoLibraryUpdatedEvent extends BusEvent {
  breakdownInfo: BreakdownInfoLibraryEntry;
  removed = false;
}

@ClassName('OperatingInfoActiveTextUpdatedEvent')
export class OperatingInfoActiveTextUpdatedEvent extends BusEvent {
  operatingInfoEntries: OperatingInfoEntry[];
  changedBy: string;
  updateSuccessful: boolean;
  tenantGuid: string;
}

@ClassName('OperatingInfoLibraryUpdatedEvent')
export class OperatingInfoLibraryUpdatedEvent extends BusEvent {
  operatingInfoLibraryEntry: OperatingInfoEntry;
  removed = false;
  changedBy: string;
  tenantGuid: string;
}

@ClassName('MaintenanceUpdatedEvent')
export class MaintenanceUpdatedEvent extends BusEvent {
  tenantSisIdsUnderMaintenance: string[];
}

@ClassName('AlarmLibraryOverrideUpdatedEvent')
export class AlarmLibraryOverrideUpdatedEvent extends BusEvent {
  tenantGuid: string;
  alarmLibraryEntry: AlarmLibraryItem;
  successful: boolean;
}

@ClassName('SisMediaStatusUpdatedEvent')
export class SisMediaStatusUpdatedEvent extends BusEvent {
  guid: string;
  tenantGuid: string;
  status: SisMediaAssetStatus;
  subStatus: SisMediaAssetSubStatus;
  activeStatus: SisMediaAssetStatus;
  statusOverrideActive: boolean;
  lastStatusChange: Date;
  updateSuccessful: boolean;
  failReason: string;
  assetCategory: SisMediaAssetCategory;
  changedBy: string;
}

@ClassName('SisMediaPlsUpdatedEvent')
export class SisMediaPlsUpdatedEvent extends BusEvent {
  assetGuid: string;
  assetStatus: SisMediaAssetStatus;
  successfullyUpdatedDisplays: string[];
  unsuccessfullyUpdatedDisplays: string[];
  displaysInSimulationMode: string[];
}

@ClassName('MediaCenterSlideUpdatedEvent')
export class MediaCenterSlideUpdatedEvent extends BusEvent {
  slide: Slide;
  removed: boolean;
  success: boolean;
  settings: boolean;
  changedBy: string;
  tenantGuid: string;
}

@ClassName('MediaCenterScreenUpdatedEvent')
export class MediaCenterScreenUpdatedEvent extends BusEvent {
  screens: Screen[];
  removed: boolean;
  success: boolean;
  changedBy: string;
  tenantGuid: string;
}

@ClassName('MediaCenterPlaylistUpdatedEvent')
export class MediaCenterPlaylistUpdatedEvent extends BusEvent {
  playlist: Playlist;
  removed: boolean;
  success: boolean;
  changedBy: string;
  tenantGuid: string;
}

@ClassName('MediaCenterThumbnailUpdatedEvent')
export class MediaCenterThumbnailUpdatedEvent extends BusEvent {
  url: string;
  thumbnailUrl: string;
}

@ClassName('MediaCenterBlobStorageUpdatedEvent')
export class MediaCenterBlobStorageUpdatedEvent extends BusEvent {
  uploadedBy?: string;
  fileName: string;
  successful: boolean;
}

@ClassName('InfoBannerUpdatedEvent')
export class InfoBannerUpdatedEvent extends BusEvent {
  infoBanner: InfoBanner;
  updateSuccessful: boolean;
  removed: boolean;
}

@ClassName('InfoBannerMappingUpdatedEvent')
export class InfoBannerMappingUpdatedEvent extends BusEvent {
  infoBannerMappings: InfoBannerMapping[];
  infoBanners: InfoBanner[];
  tenantGuid: string;
  updateSuccessful: boolean;
}

@ClassName('InfotextUpdatedEvent')
export class InfotextUpdatedEvent extends BusEvent {
  guid: string;
  categoryId: number;
  textDe: string;
  textEn: string;
  textFr: string;
  textIt: string;
  updateSuccessful: boolean;
  changedBy: string;
  failReason: string;
}

@ClassName('TimetableJourneyUpdatedEvent')
export class TimetableJourneyUpdatedEvent extends BusEvent {
  timetableJourney: TimetableJourney;
  tenantGuid: string;
  changedBy: string;
  deleted: boolean;
  updateSuccessful: boolean;
}

@ClassName('TimetableSeasonUpdatedEvent')
export class TimetableSeasonUpdatedEvent extends BusEvent {
  timetableSeason: TimetableSeason;
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('TimetableImportUpdatedEvent')
export class TimetableImportUpdatedEvent extends BusEvent {
  trackGuid: string;
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('TimetableIntervalDayUpdatedEvent')
export class TimetableIntervalDayUpdatedEvent extends BusEvent {
  timetableIntervalDay: TimetableIntervalDay;
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('LastSlopeControlUpdatedEvent')
export class LastSlopeControlUpdatedEvent extends BusEvent {
  guid: string;
  zone: number;
  times: string[];
  activeTime: number;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('SisMediaAssetOverrideUpdatedEvent')
export class SisMediaAssetOverrideUpdatedEvent extends BusEvent {
  sisMediaAssetOverrideEntry: SisMediaAssetOverrideEntry;
  operation: string;
  changedBy: string;
  lastChanged: Date;
  updateSuccessful: boolean;
}

@ClassName('SisMediaAssetEditUpdatedEvent')
export class SisMediaAssetEditUpdatedEvent extends BusEvent {
  assetEdits: SisMediaAssetEdit[];
  updateSuccessful: boolean;
  tenantGuid: string;
  operation: string;
  changedBy: string;
}

@ClassName('SisMediaAssetCockpitOrderUpdatedEvent')
export class SisMediaAssetCockpitOrderUpdatedEvent extends BusEvent {
  assetOrders: SisMediaItemOrder[];
  updateSuccessful: boolean;
  tenantGuid: string;
  changedBy: string;
}

@ClassName('UserPermissionUpdatedEvent')
export class UserPermissionUpdatedEvent extends BusEvent {
  userGuid: string;
  tenantGuid: string;
  featureId: FeatureId;
  featureAccessLevel: FeatureAccessLevel;
  adminOnly: boolean;
  changedBy: string;
}

@ClassName('MeteoInfoUpdatedEvent')
export class MeteoInfoUpdatedEvent extends BusEvent {
  meteoInfoItems: MeteoInfoItem[];
  updateSuccessful: boolean;
  tenantGuid: string;
  changedBy: string;
}

@ClassName('STnetUpdatedEvent')
export class STnetUpdatedEvent extends BusEvent {
  stnetItem: STnetItem;
}

@ClassName('STnetStatusUpdatedEvent')
export class STnetStatusUpdatedEvent extends BusEvent {
  tenantGuid: string;
  status: STnetStatus;
}

@ClassName('LogbookUpdatedEvent')
export class LogbookUpdatedEvent extends BusEvent {
  logbookEntry: LogbookEntry;
}

@ClassName('LedTickerUpdatedEvent')
export class LedTickerUpdatedEvent extends BusEvent {
  ledTicker: LedTicker;
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('SisMediaOperatingHoursUpdatedEvent')
export class SisMediaOperatingHoursUpdatedEvent extends BusEvent {
  assetGuid: string;
  assetCategory: number;
  operatingHoursEnabled: boolean;
  operatingHours: SisMediaOperatingHoursEntry[];
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('SisMediaAssetExportMappingUpdatedEvent')
export class SisMediaAssetExportMappingUpdatedEvent extends BusEvent {
  assetGuid: string;
  assetCategory: SisMediaAssetCategory;
  exportGuids: string[];
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('HtmlToPdfGeneratedEvent')
export class HtmlToPdfGeneratedEvent extends BusEvent {
  tenantGuid: string;
  pdfName: string;
  pdfUrl: string;
  successful: boolean;
  changedBy: string;
}

@ClassName('EmailDeliverySentEvent')
export class EmailDeliverySentEvent extends BusEvent {
  tenantGuid: string;
  emailText: string;
  subjectText: string;
  attachmentGuid: string;
  changedBy: string;
  successful: boolean;
}

@ClassName('EmailDeliveryRecipientUpsertedEvent')
export class EmailDeliveryRecipientUpsertedEvent extends BusEvent {
  tenantGuid: string;
  recipientGuid: string;
  name: string;
  email: string;
  location: string;
  deleted: boolean;
  changedBy: string;
  successful: boolean;
}

@ClassName('EcoModeSettingsUpdatedEvent')
export class EcoModeSettingsUpdatedEvent extends BusEvent {
  tenantGuid: string;
  ropewayEcoModeSettings: RopewayEcoModeSettings;
  changedBy: string;
  moduleUpdateSuccessful: boolean;
  updateSuccessful: boolean;
}

@ClassName('RopewaySettingsUpdatedEvent')
export class RopewaySettingsUpdatedEvent extends BusEvent {
  ropewaySettings: RopewaySettings;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('UserManagementUserUpdatedEvent')
export class UserManagementUserUpdatedEvent extends BusEvent {
  user: User;
  managedTenants: ManagedTenant[];
  changedBy: string;
  tenantGuid: string;
  updateSuccessful: boolean;
}

@ClassName('SambesiWhatsUpMessagesUpdatedEvent')
export class SambesiWhatsUpMessagesUpdatedEvent extends BusEvent {
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
  ropewaySisIds: string[];
}

@ClassName('MarkerPositionUpdatedEvent')
export class MarkerPositionUpdatedEvent extends BusEvent {
  tenantGuid: string;
  changedBy: string;
  markerGuid: string;
  successful: boolean;
}

@ClassName('TimetableRealTimeDataUpdatedEvent')
export class TimetableRealTimeDataUpdatedEvent extends BusEvent {
  tenantGuid: string;
  trackGuid: string;
  seasonGuid: string;
  realTimeDatas: TimeTableRealTimeData[];
  lastSuccessfulRealTimeImport: Date;
}

@ClassName('SlopesOperatingTimesUpdatedEvent')
export class SlopesOperatingTimesUpdatedEvent extends BusEvent {
  guid: string;
  tenantGuid: string;
  location: string;
  firstRide: string;
  lastRide: string;
  lastSlopeControl: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('WebLinkCollectionUpdatedEvent')
export class WebLinkCollectionUpdatedEvent extends BusEvent {
  webLinks: WebLink[];
  tenantGuid: string;
  operation: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('SisMapEditTokenUpdatedEvent')
export class SisMapEditTokenUpdatedEvent extends BusEvent {
  editToken: EditToken;
  tenantGuid: string;
  operation: string;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('TenantUpdatedEvent')
export class TenantUpdatedEvent extends BusEvent {
  tenant: Tenant;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('FeatureUpdatedEvent')
export class FeatureUpdatedEvent extends BusEvent {
  tenantGuid: string;
  changedBy: string;
  updateSuccessful: boolean;
  features: FeatureManagementFeature[];
}

@ClassName('RopewayAvailabilityTrendUpdatedEvent')
export class RopewayAvailabilityTrendUpdatedEvent extends BusEvent {
  tenantGuid: string;
  tenantSisId: string;
  ropewaySisId: string;
  date: string;
  ignore: boolean;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('ClampingCountResetedEvent')
export class ClampingCountResetedEvent extends BusEvent {
  tenantGuid: string;
  ropewayGuid: string;
  vehicleId: number;
  totalValueOnReset: number;
  lastReset: string;
  email: string;
  changedBy: string;
  resetSuccessful: boolean;
}

@ClassName('ParkingStatusUpdatedEvent')
export class ParkingStatusUpdatedEvent extends BusEvent {
  tenantGuid: string;
  parkingGuid: string;
  status: ParkingStatus;
  lastStatusChange: Date;
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('ParkingEditedEvent')
export class ParkingEditedEvent extends BusEvent {
  tenantGuid: string;
  parkings: Parking[];
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('ParkingExportMappingUpdatedEvent')
export class ParkingExportMappingUpdatedEvent extends BusEvent {
  tenantGuid: string;
  parkingGuid: string;
  exportGuids: string[];
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('ParkingCockpitOrderUpdatedEvent')
export class ParkingCockpitOrderUpdatedEvent extends BusEvent {
  tenantGuid: string;
  parkings: Parking[];
  changedBy: string;
  updateSuccessful: boolean;
}

@ClassName('ParkingCurrentOccupancyUpdatedEvent')
export class ParkingCurrentOccupancyUpdatedEvent extends BusEvent {
  parkingGuid: string;
  tenantGuid: string;
  currentOccupancy: number;
  occupancyTimestamp: Date;
  status: ParkingStatus;
  changedBy: string;
}

@ClassName('SisMediaSettingUpdatedEvent')
export class SisMediaSettingUpdatedEvent extends BusEvent {
  sisMediaSetting: SisMediaSetting;
  updateSuccessful: boolean;
  changedBy: string;
}

@ClassName('WebcamImageUpdatedEvent')
export class WebcamImageUpdatedEvent extends BusEvent {
  tenantGuid: string;
  deviceSisId: string;
  url: string;
}
